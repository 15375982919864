import { default as dashboardKOBOex440gMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/admin/dashboard.vue?macro=true";
import { default as loginVPv16RFpdVMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/admin/login.vue?macro=true";
import { default as choose_45categoryLaD2HIsmeXMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/choose-category.vue?macro=true";
import { default as defaultCxxtzPUIj0Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/default.vue?macro=true";
import { default as indexQT4dm6ZYOJMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/index.vue?macro=true";
import { default as check_45prizeOe4oscmHTnMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/check-prize.vue?macro=true";
import { default as claim_45prize8Zt9sp7l2oMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/claim-prize.vue?macro=true";
import { default as indexYHrZbDkvTzMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/index.vue?macro=true";
import { default as max_45played9kbTXzOe1ZMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/max-played.vue?macro=true";
import { default as no_45winXZPyWZftryMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/no-win.vue?macro=true";
import { default as prize_45claimedOqOp0ZieZmMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/prize-claimed.vue?macro=true";
import { default as winASTXAnHtQ5Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/win.vue?macro=true";
import { default as wrong_45answerizteFQLKJ5Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/wrong-answer.vue?macro=true";
import { default as answer_45correctkPjsPnKdaVMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/answer-correct.vue?macro=true";
import { default as be4mD1Ocw3LUMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/be.vue?macro=true";
import { default as congratulationsYbmhrfyNydMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/congratulations.vue?macro=true";
import { default as formWpB3oeYH42Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/form.vue?macro=true";
import { default as loginyPtubywwrwMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/login.vue?macro=true";
import { default as questionagTbsHC8hlMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/question.vue?macro=true";
import { default as thanksXFtOhR1iqjMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/thanks.vue?macro=true";
import { default as wheelDlB9095arqMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/wheel.vue?macro=true";
import { default as choose_45categoryjN3onVU1y5Meta } from "~/pages/choose-category.vue?macro=true";
import { default as wrong_45answer9GjULBXgXmMeta } from "~/pages/quiz/wrong-answer.vue?macro=true";
export default [
  {
    name: "admin-dashboard___nl",
    path: "/admin/dashboard",
    meta: dashboardKOBOex440gMeta || {},
    alias: ["/dashboard"],
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/admin/dashboard.vue")
  },
  {
    name: "admin-login___nl",
    path: "/admin/login",
    meta: loginVPv16RFpdVMeta || {},
    alias: ["/login"],
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/admin/login.vue")
  },
  {
    name: "choose-category___nl",
    path: "/choose-category",
    meta: choose_45categoryLaD2HIsmeXMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/choose-category.vue")
  },
  {
    name: "default___nl",
    path: "/default",
    meta: defaultCxxtzPUIj0Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/default.vue")
  },
  {
    name: "index___nl",
    path: "/",
    meta: indexQT4dm6ZYOJMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/index.vue")
  },
  {
    name: "quiz-check-prize___nl",
    path: "/quiz/check-prize",
    meta: check_45prizeOe4oscmHTnMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/check-prize.vue")
  },
  {
    name: "quiz-claim-prize___nl",
    path: "/quiz/claim-prize",
    meta: claim_45prize8Zt9sp7l2oMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/claim-prize.vue")
  },
  {
    name: "quiz___nl",
    path: "/quiz",
    meta: indexYHrZbDkvTzMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/index.vue")
  },
  {
    name: "quiz-max-played___nl",
    path: "/quiz/max-played",
    meta: max_45played9kbTXzOe1ZMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/max-played.vue")
  },
  {
    name: "quiz-no-win___nl",
    path: "/quiz/no-win",
    meta: no_45winXZPyWZftryMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/no-win.vue")
  },
  {
    name: "quiz-prize-claimed___nl",
    path: "/quiz/prize-claimed",
    meta: prize_45claimedOqOp0ZieZmMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/prize-claimed.vue")
  },
  {
    name: "quiz-win___nl",
    path: "/quiz/win",
    meta: winASTXAnHtQ5Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/win.vue")
  },
  {
    name: "quiz-wrong-answer___nl",
    path: "/quiz/wrong-answer",
    meta: wrong_45answerizteFQLKJ5Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/quiz/wrong-answer.vue")
  },
  {
    name: "static-answer-correct___nl",
    path: "/static/answer-correct",
    meta: answer_45correctkPjsPnKdaVMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/answer-correct.vue")
  },
  {
    name: "static-be___nl",
    path: "/static/be",
    meta: be4mD1Ocw3LUMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/be.vue")
  },
  {
    name: "static-congratulations___nl",
    path: "/static/congratulations",
    meta: congratulationsYbmhrfyNydMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/congratulations.vue")
  },
  {
    name: "static-form___nl",
    path: "/static/form",
    meta: formWpB3oeYH42Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/form.vue")
  },
  {
    name: "static-login___nl",
    path: "/static/login",
    meta: loginyPtubywwrwMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/login.vue")
  },
  {
    name: "static-question___nl",
    path: "/static/question",
    meta: questionagTbsHC8hlMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/question.vue")
  },
  {
    name: "static-thanks___nl",
    path: "/static/thanks",
    meta: thanksXFtOhR1iqjMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/thanks.vue")
  },
  {
    name: "static-wheel___nl",
    path: "/static/wheel",
    meta: wheelDlB9095arqMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20241003125644/pages/static/wheel.vue")
  },
  {
    name: "choose-category-nl___nl",
    path: "/kies-categorie",
    meta: choose_45categoryjN3onVU1y5Meta || {},
    component: () => import("~/pages/choose-category.vue")
  },
  {
    name: "wrong-answer-nl___nl",
    path: "/quiz/fout-antwoord",
    meta: wrong_45answer9GjULBXgXmMeta || {},
    component: () => import("~/pages/quiz/wrong-answer.vue")
  }
]